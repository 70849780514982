import { css, cx } from "@emotion/css";
import { Fade, SwipeableDrawer } from "@material-ui/core";
import { themeTokens, space, borderRadius } from "@octopusdeploy/design-system-tokens";
import React, { useEffect, useRef, useState } from "react";
import { useIsLargerThanIpadResolution } from "../../hooks/breakpoints";
import { isHTMLElement } from "../../utils";
import type { NavigationBarActionData } from "../NavigationBar/NavigationBarActions";
import { NavigationList } from "../NavigationList";
import type { NavigationListItem } from "../NavigationList";
import { NavigationSideBar, type BottomNavigationSideBarItem, type TopNavigationSideBarItem } from "../NavigationSideBar/NavigationSideBar";
import { ScrollArea } from "../ScrollArea";
import { GlobalLayoutTopNavBar } from "./GlobalLayoutTopNavBar";
export interface GlobalLayoutProps {
    topNavBar: {
        logo?: React.ReactElement;
        searchBar?: React.ReactElement;
        actions?: NavigationBarActionData[];
    };
    sideNavBar: {
        topItems: ReadonlyArray<TopNavigationSideBarItem>;
        bottomItems: ReadonlyArray<BottomNavigationSideBarItem>;
    };
    areaNavPanel: {
        items: ReadonlyArray<NavigationListItem>;
        expandState: AreaNavPanelExpandState;
        setExpandState: (reason: AreaNavPanelExpandState) => void;
        displayMode: "overlay" | "inline";
    };
    children: React.ReactNode;
    onSideBarClickAway?: () => void;
}
export type AreaNavPanelExpandState = {
    isExpanded: true;
} | {
    isExpanded: true;
    reason: "hover";
    hoveredElement: HTMLElement;
} | {
    isExpanded: false;
};
export function GlobalLayout({ topNavBar, sideNavBar, areaNavPanel, onSideBarClickAway, children }: GlobalLayoutProps) {
    const isLargerThanIpad = useIsLargerThanIpadResolution();
    const [isSideDrawerExpanded, setIsSideDrawerExpanded] = useState(false);
    const expandSideDrawer = () => setIsSideDrawerExpanded(true);
    const collapseSideDrawer = () => setIsSideDrawerExpanded(false);
    const mobileSideContainer = (<GlobalLayoutSideContainerDrawer isExpanded={isSideDrawerExpanded} onClose={collapseSideDrawer} onOpen={expandSideDrawer}>
            <NavigationSideBar topItems={sideNavBar.topItems} bottomItems={sideNavBar.bottomItems} onLinkClicked={collapseSideDrawer}/>
            <GlobalAreaNavListPanel isCollapsible={false} listItems={areaNavPanel.items} onLinkClicked={collapseSideDrawer}/>
        </GlobalLayoutSideContainerDrawer>);
    const sideContainer = (<GlobalLayoutSideContainerFixed onClickAway={onSideBarClickAway}>
            <NavigationSideBar topItems={sideNavBar.topItems} bottomItems={sideNavBar.bottomItems} onLinkClicked={collapseSideDrawer}/>
            <GlobalAreaNavListPanel isCollapsible={true} listItems={areaNavPanel.items} expandState={areaNavPanel.expandState} setExpandState={areaNavPanel.setExpandState} onLinkClicked={collapseSideDrawer} displayMode={areaNavPanel.displayMode}/>
        </GlobalLayoutSideContainerFixed>);
    return (<div className={rootProps}>
            <GlobalLayoutTopNavBar {...topNavBar} onExpandMenu={expandSideDrawer}/>
            <div className={mainContainerProps}>
                {isLargerThanIpad ? sideContainer : mobileSideContainer}
                <GlobalLayoutContent isRoundedCorner={!areaNavPanel.expandState.isExpanded || areaNavPanel.displayMode === "overlay" || areaNavPanel.items.length === 0}>{children}</GlobalLayoutContent>
            </div>
        </div>);
}
type GlobalLayoutSideContainerFixedProps = {
    onClickAway?: () => void;
    children: React.ReactNode;
};
type GlobalLayoutSideContainerDrawerProps = {
    isExpanded: boolean;
    onClose: () => void;
    onOpen: () => void;
    children: React.ReactNode;
};
function GlobalLayoutSideContainerFixed({ onClickAway, children }: React.PropsWithChildren<GlobalLayoutSideContainerFixedProps>) {
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const container = containerRef.current;
        if (container === null)
            return;
        const onPointerDown = (event: FocusEvent | PointerEvent) => {
            if (isHTMLElement(event.target) && !container.contains(event.target) && onClickAway) {
                onClickAway();
            }
        };
        window.addEventListener("pointerdown", onPointerDown);
        return () => {
            window.removeEventListener("pointerdown", onPointerDown);
        };
    }, [onClickAway]);
    return (<div ref={containerRef} className={globalLayoutSideContainerFixedStyles}>
            {children}
        </div>);
}
function GlobalLayoutSideContainerDrawer({ isExpanded, onOpen, onClose, children }: React.PropsWithChildren<GlobalLayoutSideContainerDrawerProps>) {
    return (<SwipeableDrawer open={isExpanded} onClose={onClose} onOpen={onOpen}>
            <div className={globalLayoutSideContainerDrawerStyles}>{children}</div>
        </SwipeableDrawer>);
}
const globalLayoutSideContainerFixedStyles = css({
    display: "flex",
    position: "relative", // Needed when AreaNavBar goes into overlay/absolute mode to allow scrolling.
});
const globalLayoutSideContainerDrawerStyles = css({
    display: "flex",
    backgroundColor: themeTokens.color.navigation.background.primary,
    height: "100%",
});
type GlobalAreaNavListPanelProps = {
    listItems: ReadonlyArray<NavigationListItem>;
    onLinkClicked?: () => void;
} & ({
    isCollapsible: false;
} | {
    isCollapsible: true;
    expandState: AreaNavPanelExpandState;
    setExpandState: (expandState: AreaNavPanelExpandState) => void;
    displayMode: "overlay" | "inline";
});
function GlobalAreaNavListPanel(props: GlobalAreaNavListPanelProps) {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const mouseOutTimeout = useRef<number>();
    useEffect(() => {
        if (!props.isCollapsible || props.displayMode !== "overlay")
            return;
        const containerElement = containerRef.current;
        const hoverTriggerElement = props.expandState.isExpanded && "reason" in props.expandState && props.expandState.reason === "hover" ? props.expandState.hoveredElement : undefined;
        if (!hoverTriggerElement || !containerElement || !props.isCollapsible)
            return;
        const onMouseOver = (event: MouseEvent) => {
            if (isHTMLElement(event.target) && (containerElement.contains(event.target) || hoverTriggerElement.contains(event.target))) {
                window.clearTimeout(mouseOutTimeout.current);
                mouseOutTimeout.current = undefined;
            }
            else {
                if (!mouseOutTimeout.current) {
                    mouseOutTimeout.current = window.setTimeout(() => {
                        props.setExpandState({ isExpanded: false });
                    }, 200);
                }
            }
        };
        window.addEventListener("mouseover", onMouseOver);
        return () => {
            window.removeEventListener("mouseover", onMouseOver);
        };
    });
    if (props.listItems.length === 0) {
        return null;
    }
    if (!props.isCollapsible) {
        return (<div className={cx(globalAreaNavListPanelCommonStyles, globalAreaNavListPanelRelativeStyles)}>
                <NavigationList listItems={props.listItems} onLinkClicked={props.onLinkClicked}/>
            </div>);
    }
    const { displayMode, expandState, setExpandState } = props;
    if (displayMode === "inline") {
        return (<div ref={containerRef} className={cx(globalAreaNavListPanelCommonStyles, globalAreaNavListPanelRelativeStyles)}>
                <AreaNavPanelCollapsedToggle isPanelExpanded={expandState.isExpanded} onToggle={() => setExpandState(expandState.isExpanded ? { isExpanded: false } : { isExpanded: true })}/>
                {expandState.isExpanded && <NavigationList listItems={props.listItems} onLinkClicked={props.onLinkClicked}/>}
            </div>);
    }
    return (<Fade ref={containerRef} in={expandState.isExpanded}>
            <div className={cx(globalAreaNavListPanelCommonStyles, globalAreaNavListPanelAbsoluteStyles)}>
                <NavigationList listItems={props.listItems} onLinkClicked={props.onLinkClicked}/>
            </div>
        </Fade>);
}
const toggleAreaNavPanelId = "toggleAreaNavPanel";
const globalAreaNavListPanelCommonStyles = css({
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    boxShadow: themeTokens.shadow.layoutPrimary,
    borderTopLeftRadius: borderRadius.medium,
    overflow: "hidden",
    "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
    },
    [`:hover #${toggleAreaNavPanelId}`]: {
        opacity: "0.4",
        visibility: "visible",
    },
});
const globalAreaNavListPanelAbsoluteStyles = css({
    position: "absolute",
    left: "4.25rem",
    height: "100%",
    boxShadow: themeTokens.shadow.large,
    zIndex: 1,
    // Need a solid background when we're in absolute mode since we're overlaying other content.
    backgroundColor: themeTokens.color.background.primary.default,
});
const globalAreaNavListPanelRelativeStyles = css({
    position: "relative",
});
function GlobalLayoutContent({ isRoundedCorner, children }: React.PropsWithChildren<{
    isRoundedCorner: boolean;
}>) {
    return (<main className={cx(globalLayoutContentStyles, { [globalLayoutContentWithoutAreaNavPanelStyles]: isRoundedCorner })}>
            <ScrollArea>{children}</ScrollArea>
        </main>);
}
interface AreaNavPanelCollapsedToggleProps {
    isPanelExpanded: boolean;
    onToggle: () => void;
}
function AreaNavPanelCollapsedToggle({ isPanelExpanded, onToggle }: AreaNavPanelCollapsedToggleProps) {
    const handleToggle = React.useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        onToggle();
    }, [onToggle]);
    return (<div id={toggleAreaNavPanelId} className={toggleStyles} onClick={handleToggle}>
            <em className={isPanelExpanded ? "fa-solid fa-angle-double-left" : "fa-solid fa-angle-double-right"}/>
        </div>);
}
const toggleStyles = css({
    position: "absolute",
    opacity: "0",
    visibility: "hidden",
    transition: "opacity 300ms",
    transitionTimingFunction: "ease-in",
    right: space["16"],
    top: space["12"],
    cursor: "pointer",
    ".MuiIconButton-root": {
        color: themeTokens.color.icon.secondary,
    },
});
const globalLayoutContentStyles = css({
    flex: 1,
    isolation: "isolate",
    boxShadow: themeTokens.shadow.layoutPrimary,
    background: themeTokens.color.background.supplemantary.default,
    // Prevents content from overflowing the width of this container
    minWidth: 0,
});
const globalLayoutContentWithoutAreaNavPanelStyles = css({
    borderTopLeftRadius: borderRadius.medium,
});
const rootProps = css({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    backgroundColor: themeTokens.color.background.secondary.default,
});
const mainContainerProps = css({
    flex: 1,
    display: "flex",
    minHeight: 0,
});
