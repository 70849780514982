import { css, cx, keyframes } from "@emotion/css";
import { borderRadius, colorScales } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { FallbackAvatarIcon } from "./FallbackAvatarIcon";
const avatarSizes = [56, 48, 40, 36, 32, 24, 20] as const;
type AvatarSize = (typeof avatarSizes)[number];
type AvatarShape = "circular" | "squared";
interface AvatarDimensionProps {
    /**
     * The shape of the avatar
     */
    shape: AvatarShape;
    /**
     * The size of the avatar
     */
    size: AvatarSize;
}
export interface AvatarImageProps extends AvatarDimensionProps {
    /**
     * The src to use for the avatar image
     */
    src: string;
    /**
     * The alt text for the image
     */
    alt: string;
}
export interface AvatarTextProps extends AvatarDimensionProps {
    /**
     * The text to use for the avatar
     */
    text: string;
}
export interface AvatarIconProps extends AvatarDimensionProps {
    /**
     * The icon to use for the avatar
     */
    icon: React.ReactElement;
}
export type AvatarProps = AvatarImageProps | AvatarTextProps | AvatarIconProps;
export function Avatar(props: AvatarProps) {
    if (isAvatarImageProps(props)) {
        return <AvatarImage size={props.size} src={props.src} alt={props.alt} shape={props.shape}/>;
    }
    else if (isAvatarTextProps(props)) {
        return <AvatarText size={props.size} text={props.text} shape={props.shape}/>;
    }
    return <AvatarIcon size={props.size} shape={props.shape} icon={props.icon}/>;
}
function isAvatarImageProps(props: AvatarProps): props is AvatarImageProps {
    return "src" in props;
}
function isAvatarTextProps(props: AvatarProps): props is AvatarTextProps {
    return "text" in props;
}
type AvatarLoaderProps = AvatarDimensionProps;
function AvatarLoader({ size, shape }: AvatarLoaderProps) {
    return <div role={"progressbar"} aria-label={"Loading Avatar"} className={cx(avatarLoaderRootStyles, getAvatarDimensionStyles({ size, shape }))}/>;
}
function AvatarText({ size, text, shape }: AvatarTextProps) {
    return <div className={cx(avatarTextRootStyles, getAvatarDimensionStyles({ size, shape }))}>{text}</div>;
}
function AvatarIcon({ size, shape, icon }: AvatarIconProps) {
    return (<div className={cx(avatarSvgRootStyles, getAvatarDimensionStyles({ size, shape }))}>
            <AvatarSvgIconContainer>{icon}</AvatarSvgIconContainer>
        </div>);
}
function AvatarImage({ size, shape, src, alt }: AvatarImageProps) {
    const canLoadImage = useCanLoadImage(src);
    if (canLoadImage === "loading") {
        return <AvatarLoader size={size} shape={shape}/>;
    }
    if (canLoadImage !== true) {
        return <AvatarIcon size={size} shape={shape} icon={<FallbackAvatarIcon />}/>;
    }
    return (<div className={cx(avatarImageRootStyles, getAvatarDimensionStyles({ size, shape }))}>
            <img className={avatarImageStyles} src={src} alt={alt}/>
        </div>);
}
function getAvatarDimensionStyles({ size, shape }: AvatarDimensionProps) {
    return cx(avatarSizesStyles[size], getShapeStyles(shape, size));
}
function useCanLoadImage(src: string) {
    const [loaded, setLoaded] = React.useState<"error" | "loading" | true>("loading");
    React.useEffect(() => {
        if (!src) {
            return undefined;
        }
        setLoaded("loading");
        let active = true;
        const image = new Image();
        image.onload = () => {
            if (!active) {
                return;
            }
            setLoaded(true);
        };
        image.onerror = () => {
            if (!active) {
                return;
            }
            setLoaded("error");
        };
        image.src = src;
        return () => {
            active = false;
        };
    }, [src]);
    return loaded;
}
const rootAvatarStyles = css({
    display: "flex",
    userSelect: "none",
    overflow: "hidden",
    backgroundColor: colorScales.navy["100"],
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Roboto",
    fontWeight: "400",
});
const avatarTextRootStyles = cx(rootAvatarStyles, css({
    backgroundColor: colorScales.blue["500"],
    color: colorScales.white,
}));
const avatarImageRootStyles = rootAvatarStyles;
const avatarImageStyles = css({
    objectFit: "cover",
    color: "transparent",
    width: "100%",
    height: "100%",
});
const pulseKeyframes = keyframes({
    "0%": { opacity: 1 },
    "50%": { opacity: 0.5 },
    "100%": { opacity: 1 },
});
const avatarLoaderRootStyles = cx(rootAvatarStyles, css({
    backgroundColor: colorScales.navy["200"],
    animation: `${pulseKeyframes} 1.5s linear 0s infinite`,
}));
const avatarSvgRootStyles = rootAvatarStyles;
function getShapeStyles(shape: AvatarShape, size: AvatarSize) {
    switch (shape) {
        case "squared":
            if (size <= 24)
                return squaredAvatarSmallStyles;
            if (size <= 40)
                return squaredAvatarMediumStyles;
            return squaredAvatarLargeStyles;
        case "circular":
            return circleAvatarStyles;
    }
}
const circleAvatarStyles = css({
    borderRadius: borderRadius.circle,
});
const squaredAvatarLargeStyles = css({
    borderRadius: borderRadius.large,
});
const squaredAvatarMediumStyles = css({
    borderRadius: borderRadius.medium,
});
const squaredAvatarSmallStyles = css({
    borderRadius: borderRadius.small,
});
//The scaling factor in px based on the dimensions of an avatar
const textSizeScalingFactor = 0.4375;
const avatarSizesStyles = createAvatarSizeStyles(avatarSizes);
type CssClassName = string;
function createAvatarSizeStyles(sizes: ReadonlyArray<AvatarSize>): Record<AvatarSize, CssClassName> {
    return sizes.reduce<Record<AvatarSize, CssClassName>>((prev, current: AvatarSize) => ({
        ...prev,
        [current]: createAvatarSizeStyle(current),
    }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as Record<AvatarSize, CssClassName>);
}
function createAvatarSizeStyle(size: AvatarSize): CssClassName {
    return css({
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${Number(size) * textSizeScalingFactor}px`,
        lineHeight: `${Number(size) * textSizeScalingFactor}px`,
    });
}
function AvatarSvgIconContainer({ children }: React.PropsWithChildren<{}>) {
    return <div className={avatarSvgIconContainerStyles}>{children}</div>;
}
const avatarSvgIconContainerStyles = css({
    flex: "0.75",
});
