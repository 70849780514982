import { css, cx } from "@emotion/css";
import { borderRadius, themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { v4 } from "uuid";
const ScrollAreaContext = React.createContext<Element[]>([document.scrollingElement ?? document.documentElement]);
interface ScrollAreaProviderProps {
    scrollAreas: Element[];
    children: React.ReactNode;
}
function ScrollAreaProvider({ scrollAreas, children }: ScrollAreaProviderProps) {
    return <ScrollAreaContext.Provider value={scrollAreas}>{children}</ScrollAreaContext.Provider>;
}
interface ScrollAreaProps {
    children: React.ReactNode;
    flex?: boolean;
}
export function ScrollArea({ children, flex }: ScrollAreaProps) {
    const parentScrollArea = useScrollAreaTrail();
    const [scrollAreaElement, setScrollAreaElement] = React.useState<HTMLDivElement | null>(null);
    const scrollAreas = React.useMemo(() => {
        if (!scrollAreaElement) {
            return parentScrollArea;
        }
        return [...parentScrollArea, scrollAreaElement];
    }, [parentScrollArea, scrollAreaElement]);
    const id = `scroll-${v4()}`;
    return (<ScrollAreaProvider scrollAreas={scrollAreas}>
            <div className={cx(scrollAreaStyles, scrollbarStyles, flex ? flexStyles : blockStyles)} id={id} ref={(el) => setScrollAreaElement(el)}>
                {children}
            </div>
        </ScrollAreaProvider>);
}
export const scrollbarStyles = css({
    "@supports (scrollbar-color: auto)": {
        scrollbarColor: `${themeTokens.color.scrollbar.handle} ${themeTokens.color.scrollbar.background}`,
    },
    //Although we may apply some styles globally, we specifically
    //need styles such as these locally here to ensure safari behaves.
    //This only applies to these non standard selectors.
    "@supports selector(::-webkit-scrollbar)": {
        "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: `${themeTokens.color.scrollbar.background}`,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: themeTokens.color.scrollbar.handle,
            borderRadius: borderRadius.large, //required for safari
        },
    },
});
const scrollAreaStyles = css({
    overflow: "auto",
});
const flexStyles = css({
    flex: 1,
});
const blockStyles = css({
    height: "100%",
});
export function useCurrentScrollArea() {
    const scrollAreas = useScrollAreaTrail();
    return React.useMemo(() => scrollAreas[scrollAreas.length - 1], [scrollAreas]);
}
export function useScrollAreaTrail() {
    return React.useContext(ScrollAreaContext);
}
