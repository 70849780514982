import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
export const navigationSideBarItemStyles = css({
    font: "400 0.75rem/1rem Roboto",
    color: themeTokens.color.text.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    height: "4rem",
    background: "transparent",
    ":focus-visible": {
        background: themeTokens.color.navigation.link.background.hover,
        boxShadow: themeTokens.shadow.focused,
        zIndex: 1,
    },
    ":hover": {
        backgroundColor: themeTokens.color.background.secondary.hovered,
    },
});
