import { css } from "@emotion/css";
import { letterSpacing, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { IconButton } from "../IconButton";
export interface PaginationProps {
    /**
     * The label for the pagination controls.
     */
    label: string;
    /**
     * The total results that have been paginated.
     */
    totalResults: number;
    /**
     * The current page being displayed.
     */
    currentPage: number;
    /**
     * The number of items per page.
     */
    itemsPerPage: number;
    /**
     * The callback to run when the page is changed.
     */
    onPageChange: (page: number) => void;
}
export function Pagination({ label, totalResults, currentPage, itemsPerPage, onPageChange }: PaginationProps) {
    const pageStart = Math.min((currentPage - 1) * itemsPerPage + 1, totalResults);
    const pageEnd = Math.min(currentPage * itemsPerPage, totalResults);
    const text = `${label}: ${pageStart}-${pageEnd} of ${totalResults}`;
    return (<div className={paginationWrapper}>
            <span className={textStyles}>{text}</span>
            <IconButton onClick={() => onPageChange(currentPage - 1)} icon="ChevronLeft" disabled={currentPage === 1} disableRipple={true}/>
            <IconButton onClick={() => onPageChange(currentPage + 1)} icon="ChevronRight" disabled={currentPage * itemsPerPage >= totalResults} disableRipple={true}/>
        </div>);
}
const textStyles = css({
    whiteSpace: "nowrap",
});
const paginationWrapper = css({
    display: "flex",
    alignItems: "center",
    gap: space["16"],
    color: themeTokens.color.text.secondary,
    font: text.interface.body.default.medium,
    letterSpacing: letterSpacing.wider,
});
